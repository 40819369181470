@media screen and (max-width: 1199.98px) {
  // main for page
  .hero {
    h1 {
      font-size: 64px;

      .decor {
        &:after {
          width: 162px;
          height: 130px;
          right: -164px;
        }
      }
    }
  }

  //stat block
  .stat {
    .stat-blocks {

      flex-wrap: wrap;

      .stat-block {
        flex: 0 1 32%;
        margin-bottom: 25px;

        &.fiolet {
        }

        &.yellow {
          flex: 0 1 50%;
        }
      }
    }
  }

  //about
  .about {
    padding-top: 40px;
    padding-bottom: 40px;

    .about-title {
      font-size: 56px;

      img {
        width: 200px;
      }

      .decor {
        top: -87%;
        right: 0;
      }
    }

    .about-text {
      font-size: 38px;
    }
  }

  // features
  .features {
    padding: 60px 0 80px;

    h2 {
      font-size: 56px;
    }
  }

  // services
  .services {
    padding-bottom: 40px;

    .accordion {
      .accordion-item {
        .accordion-header {

          button.accordion-button {
            font-size: 24px;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 991.98px) {
  // block form
  .form-block {
    padding: 52px 16px 16px;

    .form-title {
      font-size: 32px;
    }

    .form-text {
      font-size: 14px;
    }

    .form {
      padding: 30px 24px;
    }


    .block-input {
      width: 100%;
      margin-bottom: 26px;

      label {
        font-size: 14px;
      }
    }

    .form-block-left {
      flex: 0 1 100%;
      width: 100%;
    }

    .form-block-right {
      flex: 0 1 100%;
      width: 100%;
    }
  }

  // cta
  .cta-wrap {
    padding-bottom: 0;
    .cta {
      padding: 52px 24px 460px;

      .cta-title {
        font-size: 42px;
        text-align: center;
      }

      .cta-text {
        font-size: 14px;
        text-align: center;
      }

      .decor {
        position: absolute;
        top: 300px;
      }

      .e-icon {
        width: 90px;
      }

      .decor-block {
        padding: 20px 40px;

        .title {
          font-size: 24px;
        }


        &.decor-block-1 {
          top: 34%;
          transform: rotate(3deg);
        }

        &.decor-block-2 {
          top: 16%;
          transform: rotate(-3deg);
        }
      }
    }
  }

  //ecosystem
  .ecosystem {
    padding-bottom: 80px;
    padding-top: 50px;

    .ecosystem-text {
      font-size: 14px;
      order: 2;
    }

    .ecosystem-title {
      font-size: 52px;
      order: 1;

      .decor {

        &:after {
          width: 69px;
          height: 60px;
          right: -80px;
        }
      }
    }

    .ecosystem-smart {

      .decor {
        width: 90%;

        .decor-price {
          font-size: 52px;
        }

        .decor-text {
          font-size: 18px;
        }
      }
    }
  }

  // process
  .process {
    padding-bottom: 0;

    .process-title {
      h2 {
        font-size: 32px;
      }

      img {
        width: 80px;
      }
    }


    .process-block {
      border-radius: 20px;
      padding: 24px;

      .p-title {
        font-size: 24px;
      }

      .p-number {
        font-size: 24px;
      }

    }

    .p-status-start {
      margin-bottom: -25px;
    }

    .all-process {
      position: relative;
      &:after {
        display: none;
      }
    }
    .img-status {
      width: 90px;
    }
  }

  //team
  .team {
    padding-bottom: 40px;

    h2 {
      font-size: 42px;
    }

    .teammate {
      .teammate-name {
        font-size: 26px;
      }


      .teammate-btn {
        top: 16px;
        right: 16px;
      }

      .power {
        .superpower, .superpower-top {
          padding: 24px 50px 24px 24px;
        }

        .superpower-top {
          padding-top: 36px;
        }

      }
    }

  }
}

@media screen and (max-width: 767.98px) {

  // main for page
  .hero {
    h1 {
      font-size: 38px;

      .decor {
        &:after {
            width: 75px;
            height: 60px;
            right: -82px;
            top: -20px;
        }
      }
    }

    .text-md {
        font-size: 12px;
    }

    .hero-content {
        overflow: hidden;

        img {
          width: 26px;
        }
    }
  }

  //stat block
  .stat {
    padding: 60px 16px 20px;

    p {
      font-size: 12px;
    }

    .stat-title {
      font-size: 14px;
    }

    .stat-title-bold {
        font-size: 32px;
    }

    .stat-blocks {
      .stat-block {
        border-radius: 16px;
        padding-top: 12px;
        flex: 0 1 49%;

        &.stat-1 {
          order: 1;
        }
        &.stat-2 {
          order: 2;
        }
        &.stat-3 {
          order: 5;
        }
        &.stat-4 {
          order: 3;
        }
        &.stat-5 {
          order: 4;
        }

        &.yellow {
          flex: 0 1 100%;
        }
      }
    }
  }

  //about
  .about {
    padding-top: 30px;
    padding-bottom: 40px;

    .about-title {
      font-size: 56px;

      img {
        width: 170px;
      }

      .decor {
        top: -54%;
      }
    }

    .about-text {
      font-size: 32px;
    }
  }

  // features
  .features {
    padding: 10px 0 10px;

    h2 {
      font-size: 32px;
    }


    .my-card-body {
      padding: 24px;
      .title {
        font-size: 24px;
        margin-bottom: 15px;
      }

      .text {
        font-size: 12px;
      }

      .button {
        font-size: 12px;
      }
    }
  }

  .mob-slider-features {

    .my-card-body {
      img {
        height: 120px;
      }
    }
  }

  // smarttrek
  .smarttrek {
    border-radius: 20px;
    margin-bottom: 100px;

    .swiper-slide {
      padding: 46px 16px 0 16px;
    }

    .head-name {
      font-size: 16px;
    }

    h2 {
      font-size: 32px;
    }

    .smart-item {
      padding: 24px 24px 24px 42px;
      gap: 8px;

      .title {
        font-size: 20px;
      }

      .text {
        font-size: 13px;

        br {
          display: none;
        }
      }

      .decor {
        top: 37%;
        left: -30px;
        img {
          width: 68px;
        }

        &.decor-2 {
          left: -19px;
          top: 33%;

          img {
            width: 50px;
          }
        }

        &.decor-3 {
          left: -25px;
          top: 31%;

          img {
            width: 70px;
          }
        }
      }

    }

  }

  // main services
  .main-services {
    padding-bottom: 0;

    h2 {
      font-size: 32px;

      span {
        display: block;
        &:after {
          width: 38px;
          height: 70px;
          right: 24px;
          top: 24px;
          z-index: 100;
        }
      }
    }

    .services-block {
      padding: 14px;
      border-radius: 20px;

      .services-body {
        padding: 16px 12px;
        .services-name {
          font-size: 22px;
        }

        .services-list {
          ul {
            li {
              padding-bottom: 8px;
              padding-left: 34px;
              padding-top: 8px;

              &:before {
                left: 0;
                top: 50%;
                width: 22px;
                height: 23px;
                background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none"><circle cx="11" cy="11.6992" r="11" fill="%237949F5" fill-opacity="0.2"/><circle cx="10.9976" cy="11.6993" r="5.21053" fill="%237949F5"/></svg>') no-repeat center;
              }
            }
          }
        }

      }
    }
  }

  //team
  .team {
    padding-bottom: 20px;

    h2 {
      font-size: 32px;
    }
  }

  // services
  .services {
    padding-bottom: 10px;

    h2 {
      font-size: 28px;
    }

    .accordion {
      .accordion-item {
        .accordion-header {

          button.accordion-button {
            font-size: 18px;
            padding: 5px 20px 5px 24px;
            height: 72px;


            &:after {
              width: 48px;
              height: 48px;
              right: 10px;
              bottom: 12px;
              background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 64 65" fill="none"><rect x="64" y="0.699219" width="64" height="64" rx="32" transform="rotate(90 64 0.699219)" fill="%23F7FF54"/><path d="M30.6517 38.9191L30.6517 22.6896L33.318 22.6896L33.3189 38.9181L40.4701 31.7669L42.3558 33.6525L31.9849 44.0234L21.614 33.6525L23.4996 31.7669L30.6517 38.9191Z" fill="%237949F5"/></svg>') no-repeat center;
            }

            &:hover {
              &:after {
                background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 64 65" fill="none"><rect x="64" y="0.699219" width="64" height="64" rx="32" transform="rotate(90 64 0.699219)" fill="%23F7FF54"/><path d="M30.6517 38.9191L30.6517 22.6896L33.318 22.6896L33.3189 38.9181L40.4701 31.7669L42.3558 33.6525L31.9849 44.0234L21.614 33.6525L23.4996 31.7669L30.6517 38.9191Z" fill="%237949F5"/></svg>') no-repeat center;
              }
            }

            &:not(.collapsed)::after {
              bottom: -22px;
            }

            svg {
              width: 28px;
            }

          }
        }

        .accordion-body {
          padding: 16px 50px 16px 24px;
        }
      }
    }
  }

  // plans
  .plans {
    padding-bottom: 50px;
    .plans-title {
      h2 {
        font-size: 32px;

        .h2-content {
          display: block;
          .decor {
            top: 60px;
            right: 10px;
            transform: none;
            width: 80px;

          }
        }
      }
    }

    .plan {
      padding: 32px 24px 24px;

      .plan-number {
        font-size: 22px;
      }

      .plan-name {
        font-size: 42px;
      }
    }

  }

  // cta
  .cta-wrap {
    .cta {
      padding: 52px 24px 820px;
    }
  }

  // faq
  .faq {
    .faq-title {
      font-size: 32px;

      .decor {
        left: 50%;
        top: -75px;

        img {
          width: 100px;
        }
      }
    }

    .faq-item {
      .ava-faq {
        width: 52px;
        height: 52px;
      }

      .faq-text {
        border-radius: 16px;
        padding: 16px 24px;
        font-size: 14px;

      }
    }
  }
}


@media screen and (max-width: 360px) {
  // features
  .mob-slider-features {

    .my-card-body {
      min-height: 400px;
      img {
        height: 100px;
      }
    }
  }

  .cta-wrap .cta .decor-block {
    padding: 20px 24px;
  }

  //ecosystem
  .ecosystem {
    .ecosystem-title {
      font-size: 42px;
    }
  }

  .services .accordion .accordion-item .accordion-header button.accordion-button {
    font-size: 16px;
  }

}
