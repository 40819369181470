@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400..900&family=Manrope:wght@200..800&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400..900&family=Manrope:wght@200..800&family=Moderustic:wght@300..800&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

$font: 'Rubik', sans-serif;
$textfont: 'Moderustic', sans-serif;

$main: #7949F5;
$second: #F7FF54;

@function rem($value) {
    @return #{$value / 14}rem;
}

* {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;
}

html {
    font-size: 14px;

    @media screen and (max-width: 1199.98px) {
        overflow-x: hidden;
    }
}

body {
    text-size-adjust: 100%;
    background: #fff;
    color: #252A30;
    font-weight: 500;
    line-height: 1.5;
    overflow-x: hidden;
    position: relative;
    word-break: break-word;
    font-family: $textfont, sans-serif;
}

img {
    max-width: 100%;
    user-select: none;
    -webkit-user-drag: none;
    max-height: 100%;
}

a {
    text-decoration: none;
    transition: all .3s;
    color: inherit;

    &:hover {
        color: $second;
        text-decoration: none;
    }
}

button {
    box-shadow: none;
    border-radius: 0;
    border: none;
    outline: none;
    background: transparent;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

h2 {
    line-height: 1.25;
}

section {
    margin-bottom: 4rem;
}

p {
    margin: 0;
}

.text-md {
    font-size: 16px;
}

.button {
    padding: 18px 44px;
    border-radius: 80px;
    color: $main;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    background-color: $second;
    font-size: 14px;

    @media screen and (max-width: 767.98px) {
        font-size: 12px;
    }

    &.main {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 12px 10px 30px;
    }

    &.second {
        background-color: $main;
        color: #fff;
        &:hover {
            color: #fff;
        }
    }

    &:hover {
        transition: all .2s ease;
        color: $main;
    }


}

// animation for button
.button {
    transition: background 0.3s ease-in-out;
    gap: 0;
}

.button span,
.button svg {
    transition: transform 0.3s ease, opacity 0.3s ease;
    display: inline-block;
    transform-origin: center;
}

.button:hover {
    transition: background 0.3s ease-in-out;
    padding: 10px 30px 10px 22px;
    gap: 0;

    &.main.anim {
        color: #F7FF54;
        background-color: $main;

        rect {
            fill: $second;
        }

        path {
            fill: $main;
        }
    }

    &.second.anim {
        color: $main;
        background-color: #F7FF54;

        rect {
            fill: $main;
        }

        path {
            fill: #fff;
        }
    }
}

.button:hover span {
    order: 2;
    transform: translateX(10px);
    padding-right: 10px;
    margin-left: -10px;
}

.button:hover svg {
    order: 1;
    transform: translateX(-10px);
}


// main for page
.hero {
    h1 {
        font-family: $font;
        letter-spacing: -0.02em;
        font-weight: 800;
        font-size: 72px;
        line-height: 1;
        text-transform: uppercase;

        .ital {
            font-weight: 700;
            font-style: italic;
            color: $main;
        }

        .decor {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                right: -200px;
                top: -65px;
                width: 186px;
                height: 149px;
                background: url('../img/icon/wow.svg') no-repeat center center;
                background-size: cover;
            }
        }
    }
}

//stat block
.stat {
    background: url('../img/stat.svg') no-repeat center center;
    background-size: cover;
    border-radius: 20px;
    padding: 120px 20px 20px;

    .stat-title {
        color: #FFFFFF;
        font-weight: 600;
        font-family: $font;
        line-height: 1.25;
        font-size: 24px;
        letter-spacing: -0.02em;
    }

    .stat-title-bold {
        color: $second;
        line-height: 1;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 72px;
        font-family: $font;
        letter-spacing: -0.02em;
    }

    .stat-blocks {
        display: flex;

        .stat-block {
            border-radius: 20px;
            padding-top: 24px;
            position: relative;
            flex: 0 1 19.4%;
            background-color: #fff;
            height: max-content;

            &.fiolet {
                background-color: #AA89FF;
                color: #FFF9EE;
            }

            &.yellow {
                background-color: #F7FF54;
            }

            &.p-block {
                padding: 24px 16px 16px;
            }

            .px-block {
                padding-right: 16px;
                padding-left: 16px;
            }

            .decor {
                position: absolute;

                &.decor-1 {
                    right: 14px;
                    top: -30px;
                }
                &.decor-2 {
                    left: 20px;
                    top: -30px;
                }
                &.decor-3 {
                    right: 14px;
                    top: -20px;
                    transform: rotate(6deg);
                }
                &.decor-4 {
                    right: 14px;
                    top: -24px;
                    transform: rotate(-12deg);
                }
                &.decor-5 {
                    left: 20px;
                    top: -36px;
                    transform: rotate(40deg);
                }
            }
        }
    }
}

.about {
    padding-top: 120px;
    padding-bottom: 60px;
    h2 {
        font-size: 14px;
        font-weight: 500;
    }
    .about-title {
        font-family: $font;
        font-size: 62px;
        font-weight: 800;
        line-height: 1;
        color: $main;
        text-transform: uppercase;
        position: relative;
        letter-spacing: -0.02em;

        .decor {
            position: absolute;
            top: -87%;
            right: 0;
            z-index: -1;
        }
    }

    .about-text {
        font-weight: 600;
        font-family: $font;
        font-size: 42px;
        line-height: 1.2;
        text-transform: uppercase;
        letter-spacing: -0.02em;
    }
}

// features
.features {
    padding: 60px 0 80px;

    h2 {
        text-transform: uppercase;
        font-weight: 800;
        font-family: $font;
        font-size: 72px;
        line-height: 1.1;
        letter-spacing: -0.02em;

        span {
            color: #D4D4D4;
        }
    }

    #my-cards, #my-cards-c {
        list-style: none;
        padding-left: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(var(--cards), var(--cardHeight));
        gap: var(--cardMargin);
        padding-bottom: calc(var(--cards) * var(--cardTopPadding));
        margin-bottom: var(--cardMargin);
    }

    #my-card, .my-card1 {
        --index: 1;

        .my-card-body {
            background-color: #7949F5;
        }
    }

    #my-card2, .my-card2 {
        --index: 2;

        .my-card-body {
            background-color: #9767FF;
        }
    }

    #my-car3, .my-card3 {
        --index: 3;

        .my-card-body {
            background-color: #BB98FF;
        }
    }

    #my-card4, .my-card4 {
        --index: 4;

        .my-card-body {
            background-color: #DCCFFD;
        }
    }

    .my-card {
        position: sticky;
        top: 0;
        padding-top: calc(var(--index) * var(--cardTopPadding));
    }

    .my-card-body {
        box-sizing: border-box;
        padding: 32px 28px;
        border-radius: 20px;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        gap: 20px;
        transition: all 0.5s;
        height: 436px;
        width: 360px;
        margin: auto;
        justify-content: space-between;

        .title {
            color: #fff;
            font-size: 28px;
            line-height: 1.1;
            margin-bottom: 18px;

            &.dark {
                color: #2B1951;
            }
        }

        .text {
            color: #FFF9EE;

            &.opc {
                opacity: .7;
            }

            &.dark {
                color: #2B1951;
            }
        }

        .button {
            font-size: 14px;
            margin-top: 16px;
        }
    }

    .mob-slider-features {

        .my-card-body {
            width: 100%;
            height: 400px;
            box-shadow: none;
        }

        .slider-features-pagination .swiper-pagination-bullet {
            background: #878787;
            border-radius: 7.5rem;
            height: 4px;
            width: 20px;
        }
        .slider-features-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
            transition: all .2s ease;
            width: 40px;
            background: #7949F5;
        }
    }
}

// smarttrek
.smarttrek {
    background: url('../img/stat.svg') no-repeat center center;
    background-size: cover;
    border-radius: 20px;
    position: relative;
    margin-bottom: 100px;

    .swiper-slide {
        padding: 52px 24px 0 52px;
    }

    .head-name {
        font-weight: 500;
        font-family: $font;
        font-size: 18px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        color: #fff;
    }

    h2 {
        text-transform: uppercase;
        font-weight: 800;
        font-family: $font;
        font-size: 52px;
        line-height: 1.1;
        letter-spacing: -0.02em;
        color: #F7FF54;
    }

    .smart-item {
        padding: 24px 24px 24px 52px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: relative;
        background-color: #AA89FF;
        margin-left: 20px;

        .title {
            color: #fff;
            font-weight: 600;
            font-family: $font;
            font-size: 22px;
            line-height: 1.25;
            letter-spacing: -0.02em;
            font-style: italic;
        }

        .text {
            color: #fff;
        }

        &.active {
            background-color: #FFFFFF;

            .title {
                color: #7949F5;
            }

            .text {
                color: #252A30;
                b {
                    color: #7949F5;
                }
            }
        }

        .decor {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -40px;

            &.decor-2 {
                top: 42%;
                left: -26px;
            }

            &.decor-3 {
                top: 56%;
                left: -46px;
            }
        }
    }

    .smarttrek_slider-pagination {
        bottom: 10px;
        display: flex;
        justify-content: space-between;
        right: 0;
        position: static;
    }

    .smarttrek_slider-pagination .swiper-pagination-bullet {
        background: #878787;
        border-radius: 7.5rem;
        height: 6px;
        width: 33%;
    }
    .smarttrek_slider-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #7949F5;
    }

    .slider-navigation {
        position: relative;
        bottom: -65px;
    }

    .smart-button-prev, .smart-button-next {

        &:hover {
            circle {
                transition: all .2s ease;
                fill: #7949F5;
            }
        }
    }
}

// team
.team {
    padding-bottom: 80px;

    h2 {
        text-transform: uppercase;
        font-weight: 600;
        font-family: $font;
        font-size: 42px;
        line-height: 1.2;
        letter-spacing: -0.02em;
    }

    .teammate {
        position: relative;

        img {
            border-radius: 20px;
        }

        .teammate-name {
            font-weight: 500;
            font-family: $font;
            font-size: 26px;
            line-height: 1.2;
            letter-spacing: -0.02em;
        }

        .teammate-position {
            color: #7044E4;
        }

        .teammate-btn {
            position: absolute;
            top: 16px;
            right: 16px;
            transition: all .2s ease;

            &:hover {
                circle {
                    transition: all .2s ease;
                    fill: #F7FF54;
                }

                path {
                    transition: all .2s ease;
                    fill: #7949F5;
                }
            }
        }

        .power {
            border-radius: 20px;

            .superpower, .superpower-top {
                padding: 24px 50px 24px 24px;
                width: 100%;
                position: absolute;
                border-radius: 20px;
                font-size: 15px;

                @media screen and (max-width: 1399.98px) {
                    padding: 14px 20px 16px;
                }
            }

            .superpower-top {
                padding-top: 36px;
                background-color: #fff;
                top: 0;
                z-index: 2;
                height: 100%;
                display: none;

                @media screen and (max-width: 1399.98px) {
                    padding-top: 20px;
                }
            }

            .superpower {
                background-color: #7949F5;
                bottom: 0;
                z-index: 3;
                display: none;

                .title {
                    font-weight: 600;
                    font-family: $font;
                    font-size: 18px;
                    line-height: 1.2;
                    letter-spacing: -0.02em;
                    font-style: italic;
                    color: #F7FF54;
                }

                .text {
                    color: #fff;
                }
            }

            &.active {
                box-shadow: 5px 10.34px 47.79px #2B166078;
                z-index: 11;

                .superpower-top {
                    display: block;
                }

                .superpower {
                    display: block;
                }
            }
        }

        .decor-arrow {
            position: absolute;
            right: 14px;
            top: -88px;
            z-index: 10;
        }

        .decor-cool {
            position: absolute;
            right: -60px;
            top: 90px;
            z-index: 10;
        }

        .decor-gwork {
            position: absolute;
            left: 36px;
            top: -52px;
            z-index: 10;
        }
    }

    .slider-teammate {

        .slider-teammate-pagination .swiper-pagination-bullet {
            background: #878787;
            border-radius: 7.5rem;
            height: 4px;
            width: 20px;
        }
        .slider-teammate-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
            transition: all .2s ease;
            width: 40px;
            background: #7949F5;
        }
    }
}

// main services
.main-services {
    padding-bottom: 50px;

    h2 {
        text-transform: uppercase;
        font-weight: 600;
        font-family: $font;
        font-size: 42px;
        line-height: 1.2;
        letter-spacing: -0.02em;

        span {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                right: -48px;
                top: 3px;
                width: 46px;
                height: 83px;
                background: url('../img/icon/arrow-down.png') no-repeat center center;
                background-size: cover;
            }
        }
    }

    .services-block {
        background-color: #fff;
        padding: 16px;
        border-radius: 20px;
        box-shadow: 0 7.34px 20.79px #2B16601C;
        height: 100%;

        .services-header {
            img {
                border-radius: 10px;
            }
        }

        .services-body {
            padding: 16px;
            .services-name {
                font-weight: 500;
                font-family: $font;
                font-size: 28px;
                line-height: 1.2;
                letter-spacing: -0.02em;
            }

            .services-list {
                ul {
                    li {
                        padding-bottom: 8px;
                        padding-left: 34px;
                        padding-top: 8px;
                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 22px;
                            height: 23px;
                            background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none"><circle cx="11" cy="11.6992" r="11" fill="%237949F5" fill-opacity="0.2"/><circle cx="10.9976" cy="11.6993" r="5.21053" fill="%237949F5"/></svg>') no-repeat center;
                            background-size: contain;
                        }
                    }
                }
            }

            .services-btn {
                .button {
                    background-color: #F9F8FB;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 700;

                    &:hover {
                        color: #F7FF54;
                        background-color: $main;

                        rect {
                            fill: $second;
                        }

                        path {
                            fill: $main;
                        }
                    }
                }

            }
        }
    }

    .slider-main-services {

        .main-services-pagination .swiper-pagination-bullet {
            background: #878787;
            border-radius: 7.5rem;
            height: 4px;
            width: 20px;
        }
        .main-services-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
            transition: all .2s ease;
            width: 40px;
            background: #7949F5;
        }

        .swiper-wrapper {
            display: flex;
            align-items: stretch;
        }

        .swiper-slide {
            display: flex;
            flex-direction: column;
            height: auto;
            min-height: 100%;
            box-sizing: border-box;
        }
    }
}

// services
.services {
    padding-bottom: 80px;

    h2 {
        font-weight: 600;
        font-family: $font;
        font-size: 42px;
        line-height: 1.2;
        letter-spacing: -0.02em;
    }

    .accordion {
        .accordion-item {
            border: 0;
            .accordion-header {

                button.accordion-button {
                    background-color: #252A30;
                    color: #fff;
                    border: none;
                    font-weight: 600;
                    font-family: $font;
                    font-size: 28px;
                    line-height: 1;
                    letter-spacing: -0.02em;
                    padding: 5px 20px 5px 40px;
                    height: 108px;
                    outline: 0;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    position: relative;

                    &.collapsed {
                        border-radius: 20px !important;
                    }

                    &:focus {
                        box-shadow: none;
                    }

                    &:after {
                        content: '';
                        display: inline-block;
                        width: 64px;
                        height: 64px;
                        margin-left: auto;
                        background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65" fill="none"><rect x="64" y="0.699219" width="64" height="64" rx="32" transform="rotate(90 64 0.699219)" fill="%23F7FF54"/><path d="M30.6517 38.9191L30.6517 22.6896L33.318 22.6896L33.3189 38.9181L40.4701 31.7669L42.3558 33.6525L31.9849 44.0234L21.614 33.6525L23.4996 31.7669L30.6517 38.9191Z" fill="%237949F5"/></svg>') no-repeat center;
                        background-size: contain;
                        transform: rotate(0deg);
                        transition: all 0.3s ease;
                        position: absolute;
                        right: 20px;
                        bottom: 22px;
                    }

                    &:hover {
                        &:after {
                            transition: all 0.3s ease;
                            background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65" fill="none"><rect x="64" y="0.699219" width="64" height="64" rx="32" transform="rotate(90 64 0.699219)" fill="%23FFFFFF"/><path d="M30.6517 38.9191L30.6517 22.6896L33.318 22.6896L33.3189 38.9181L40.4701 31.7669L42.3558 33.6525L31.9849 44.0234L21.614 33.6525L23.4996 31.7669L30.6517 38.9191Z" fill="%237949F5"/></svg>') no-repeat center;                        }
                    }

                    &:not(.collapsed)::after {
                        transform: rotate(180deg);
                        bottom: -32px;
                    }
                }
            }

            .accordion-body {
                color: #FFF9EE;
                background-color: #7949F5;
                padding: 32px 120px 32px 40px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }
    }
}

// process
.process {
    padding-bottom: 70px;

    .process-title {
        h2 {
            text-transform: uppercase;
            font-weight: 600;
            font-family: $font;
            font-size: 42px;
            line-height: 1.2;
            letter-spacing: -0.02em;
        }
    }

    .process-block-wrap {
        height: 100%;
    }


    .process-block {
        background-color: #F9F8FB;
        border-radius: 20px;
        padding: 30px;
        height: 100%;

        .p-title {
            font-weight: 500;
            font-family: $font;
            font-size: 28px;
            line-height: 1.2;
            letter-spacing: -0.02em;
        }

        .p-text {
            opacity: .7;
        }

        .p-number {
            font-weight: 500;
            font-family: $font;
            font-size: 24px;
            line-height: 1.2;
            letter-spacing: -0.02em;
            color: #7949F5;
        }

        &.violet {
            background-color: #7949F5;

            .p-title {
                color: #fff;
            }

            .p-text {
                color: #fff;
            }

            .p-number {
                color: #F7FF54;
            }
        }
    }

    .p-img {
        border-radius: 14px;
    }

    .p-status-start {
        margin-bottom: -25px;
    }

    .all-process {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: 2px;
            height: 100%;
            border-right: 2px dashed #7949F580;
            left: 49.95%;
            top: 0;
        }
    }
}

// plans
.plans {
    padding-bottom: 90px;
    .plans-title {
        h2 {
            text-transform: uppercase;
            color: #252A30;
            font-family: $font;
            font-size: 52px;
            letter-spacing: -0.02em;
            line-height: 1.2;
            font-weight: 600;

            .h2-content {
                position: relative;
                .decor {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -130px;
                }
            }
        }
    }
    .plan {
        padding: 50px 40px 40px;
        border-radius: 20px;

        .plan-number {
            width: 60px;
            height: 60px;
            background-color: #252A30;
            border-radius: 16px;
            font-size: 22px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
        }

        .plan-name {
            font-family: $font;
            font-weight: 600;
            font-size: 52px;
            letter-spacing: -0.02em;
            line-height: 1.2;
            color: #151515;
            text-transform: uppercase;
        }

        .plan-desc {
            opacity: .5;
            font-weight: 500;
            letter-spacing: -0.02em;
            font-size: 14px;
        }

        .plan-list {
            li {
                position: relative;
                padding-left: 34px;
                padding-bottom: 8px;
                padding-top: 8px;

                &.no-active {
                    opacity: .4;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 22px;
                    height: 23px;
                    background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none"><circle cx="11" cy="11.0166" r="11" fill="%23252A30" fill-opacity="0.2"/><circle cx="10.9976" cy="11.0172" r="5.21053" fill="%23252A30"/></svg>') no-repeat center;
                    background-size: contain;
                }
            }
        }

        &.yellow {
            background-color: #F7FF54;
            .plan-number {
                color:  #F7FF54;
            }
        }

        &.violet {
            background-color: #7949F5;

            .plan-name {
                color: #fff;
            }

            .plan-list {
                color: #fff;

                li {
                    &:before {
                        background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none"><circle cx="11.666" cy="11.0166" r="11" fill="white" fill-opacity="0.2"/><circle cx="11.6637" cy="11.0172" r="5.21053" fill="white"/></svg>') no-repeat center;
                    }
                }
            }

            .plan-desc {
                color: #fff;
            }
        }

        &.dark {
            background-color: #252A30;

            .plan-number {
                background-color: #F7FF54;
                color: #252A30;
            }

            .plan-name {
                color: #fff;
            }

            .plan-list {
                color: #fff;

                li {
                    &:before {
                        background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none"><circle cx="11.666" cy="11.0166" r="11" fill="white" fill-opacity="0.2"/><circle cx="11.6637" cy="11.0172" r="5.21053" fill="white"/></svg>') no-repeat center;
                    }
                }
            }

            .plan-desc {
                color: #fff;
            }
        }
    }

    .btn-plan-1:hover {
        background-color: #252A30;

        rect {
            fill: $second;
        }

        path {
            fill: #252A30;
        }
    }

    .btn-plan-2:hover {
        background-color: #fff;
        color: $main;

        rect {
            fill: $main;
        }

        path {
            fill: $second;
        }
    }

    .btn-plan-3 {
        background-color: #fff;
        &:hover {
            background-color: $second;
            color: #252A30;

            rect {
                fill: #252A30;
            }

            path {
                fill: $second;
            }
        }
    }
}

// cta
.cta-wrap {
    padding-bottom: 320px;
    .cta {
        background: url('../img/advantages.svg') no-repeat center center;
        background-size: cover;
        border-radius: 20px;
        padding: 60px 60px 100px;
        position: relative;

        .cta-title {
            font-weight: 800;
            font-family: $font;
            font-size: 72px;
            line-height: 1;
            text-transform: uppercase;
            letter-spacing: -0.02em;
            color: #F7FF54;
        }

        .cta-text {
            color: #FFFFFF;
            font-weight: 600;
            font-family: $font;
            line-height: 1.25;
            font-size: 24px;
            letter-spacing: -0.02em;
        }

        .decor {
            position: absolute;
            top: 70%;
            right: 0;
        }

        .decor-block {
            position: absolute;
            padding: 20px 40px;

            .title {
                font-size: 28px;
                color: #252A30;
                font-weight: 800;
                font-family: $font;
                letter-spacing: -0.02em;
                line-height: 1.15;
                text-transform: uppercase;
            }

            .text {
                color: #252A30;
                font-size: 14px;
                line-height: 1.5;
            }

            &.decor-block-1 {
                top: 34%;
                transform: rotate(3deg);
            }

            &.decor-block-2 {
                top: 16%;
                transform: rotate(-3deg);
            }
        }
    }
}

//ecosystem
.ecosystem {
    padding-bottom: 80px;
    padding-top: 60px;

    .ecosystem-text {
        font-size: 16px;
    }
    .ecosystem-title {
        font-weight: 800;
        font-family: $font;
        font-size: 86px;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: -0.02em;
        color: #252A30;

        .decor {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                width: 111px;
                height: 97px;
                transform: translateY(-50%);
                top: 50%;
                right: -120px;
                background: url('../img/eco-smart.png') no-repeat center center;
                background-size: cover;

            }
        }
    }

    .ecosystem-smart {
        position: relative;

        .decor {
            position: absolute;
            top: 56px;
            transform: translateX(-50%);
            left: 50%;
            width: 320px;

            .decor-price {
                font-weight: 800;
                font-family: $font;
                font-size: 72px;
                line-height: 1;
                text-transform: uppercase;
                letter-spacing: -0.02em;
                color: #F7FF54;
            }

            .decor-text {
                font-size: 28px;
                color: #FFFFFF;
                font-weight: 500;
                font-family: $font;
                letter-spacing: -0.02em;
                line-height: 1.25;
            }
        }
    }
}

// faq
.faq {
    .faq-title {
        color: #252A30;
        font-size: 72px;
        font-weight: 600;
        letter-spacing: -0.02em;
        position: relative;
        text-transform: uppercase;
        font-family: $font;

        .decor {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -94%;
        }
    }

    .faq-item {
        .ava-faq {
            width: 72px;
            height: 72px;
            flex-shrink: 0;
        }

        .faq-text {
            border-radius: 24px;
            padding: 24px 32px;
            background-color: #252A30;
            color: #fff;
            font-size: 16px;

            p {
                margin: 0;
            }

            &.bg-violet {
                background-color: #7949F5;
            }
        }
    }
}

// block form
.form-block {
  background: url('../img/form.svg') no-repeat center center;
  background-size: cover;
  border-radius: 20px;
  padding: 80px 30px 60px;

    .form-title {
        color: $second;
        font-weight: 800;
        font-family: $font;
        line-height: 1.1;
        font-size: 52px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
    }

    .form-text {
        color: #FFFFFF;
        font-weight: 600;
        font-family: $font;
        line-height: 1.25;
        font-size: 16px;
        letter-spacing: -0.02em;
        opacity: .6;
    }

    .form {
        border-radius: 20px;
        background-color: #fff;
        padding: 40px;
        text-align: left;
    }

    .form-block-row {
    }

    .block-input {
        label {
            color: #252A30;
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 12px;
        }

        input, select, option {
            background-color: #F4F4F4;
            padding: 16px 24px;
            border-radius: 12px;
            color: #AFAFAF;
            font-size: 14px;
            border: 0;
            outline: 0;
            width: 100%;
            font-weight: 500;
        }

        select {
            background-color: #F7FF54;
            color: #000;
            position: relative;
            appearance: none;
            padding: 16px 36px 16px 24px;
        }

        option {
            color: #252A30;
        }

        .select-wrapper {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                right: 24px;
                top: 50%;
                transform: translateY(-50%);
                width: 18px;
                height: 11px;
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="11" fill="none" viewBox="0 0 18 11"><path d="M1 1.56641L9.00003 9.56638L17 1.56641" stroke="%23252A30" stroke-width="2" stroke-linecap="round"/></svg>');
                background-size: contain;
                background-repeat: no-repeat;
                pointer-events: none;
            }
        }
    }

    .form-block-left {
        flex: 0 1 36%;
    }

    .form-block-right {
        flex: 0 1 56%;
    }
}

// modal
.modal {
    .modal-dialog {
        max-width: 1280px;
    }

    .modal-content {
        background: inherit;
        border: none;
        position: relative;
    }

    .form-block {
        padding: 60px 30px 40px;

        @media screen and (max-width: 767.98px) {
            padding: 50px 10px 20px;

            .form {
                padding: 30px 16px;
            }
        }
    }

    .modal__close {
        width: 48px;
        height: 48px;
        position: absolute;
        top: 24px;
        right: 24px;
        opacity: 1;

        @media screen and (max-width: 767.98px) {
            width: 36px;
            height: 36px;
            right: 8px;
            top: 8px;

            svg {
                width: 36px;
                height: 36px;
            }
        }

        &:hover {
            transition: all .2s ease;
            opacity: .7;
        }

        &:focus {
            box-shadow: none;
        }
    }
}