.footer {
  margin-top: 140px;
  padding-bottom: 46px;
  padding-top: 70px;
  position: relative;
  background-color: $main;
  border-top-left-radius: 50% 60%;
  border-top-right-radius: 50% 60%;

  margin-left: -140px;
  margin-right: -140px;

  @media screen and (max-width: 991.98px) {
    margin-top: 100px;
  }

  @media screen and (max-width: 767.98px) {
    margin-left: auto;
    margin-right: auto;
    border-top-left-radius: 80% 15%;
    border-top-right-radius: 80% 15%;
    padding-top: 40px;
    margin-top: 70px;

    img.logo-footer {
      width: 130px;
    }
  }

  .footer-title {
    font-size: 62px;
    font-family: $font;
    color: $second;
    font-weight: 800;
    line-height: 1.1;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    position: relative;

    @media screen and (max-width: 991.98px) {
      font-size: 52px;
    }

    @media screen and (max-width: 767.98px) {
      font-size: 32px;
    }

    &:after {
      content: "";
      position: absolute;
      right: -74px;
      top: -12px;
      width: 82px;
      height: 89px;
      background: url('../img/icon/footer-2.svg') no-repeat center center;
      background-size: cover;

      @media screen and (max-width: 767.98px) {
        height: 54px;
        right: -54px;
        top: 52px;
        width: 51px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: -78px;
      top: 40px;
      width: 57px;
      height: 72px;
      background: url('../img/icon/footer-1.svg') no-repeat center center;
      background-size: cover;

      @media screen and (max-width: 767.98px) {
        width: 40px;
        height: 50px;
        left: -36px;
        top: -22px;
      }
    }
  }

  .footer-social {
    a {
      span {
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.03em;
      }

      &:hover {
        transition: all .2s ease;
        opacity: .7;
      }
    }
  }

  .footer-menu {
    .menu-list {
      display: flex;
      align-items: center;
      gap: 14px;
      flex-wrap: wrap;
      justify-content: center;

      @media screen and (max-width: 767.98px) {
        flex-direction: column;
      }

      a {
        color: #FFFFFF;
        font-size: 14px;
        padding: 4px 10px;

        &:hover {
          transition: all .2s ease;
          opacity: .7;
        }
      }
    }
  }

  .footer-bottom {
    .footer-bottom-left {
      color: #FFFFFF;
    }
    .footer-bottom-right {
      a {
        text-decoration: underline;
        font-size: 15px;
        opacity: .4;
        color: #fff;

        &:hover {
          opacity: 1;
          transition: all .2s ease;
        }
      }
    }
  }

  .footer-decor {
    position: absolute;
    top: 0;
  }

  .top-arrow {
    width: 96px;
    height: 96px;

    svg {
      transition: all .2s ease;
    }

    &:hover {
      svg {
        margin-bottom: 10px;
      }
    }

    &.yellow {
      background-color: $second;
      border-radius: 32px;
    }
  }
}