.text-big {
  font-size: 72px;
  font-family: $font;
  color: $main;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -0.02em;

  &.light {
    color: #FFF9EE;
  }

  span {
    font-size: 30px;
  }

  @media screen and (max-width: 1399px) {
    font-size: 64px;
  }

  @media screen and (max-width: 767.98px) {
    font-size: 44px;
  }

}

.violet {
  color: $main !important;
}

@media screen and (min-width: 768px) {
  .px-default {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }
}