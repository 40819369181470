$primary: #7949F5;

@import "~bootstrap/scss/bootstrap";
@import 'swiper/swiper-bundle.css';

@import "components/config";
@import "components/bootstrap";
@import "components/header";
@import "components/footer";
@import "components/media";
