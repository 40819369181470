.header {
  padding-top: 20px;
  padding-bottom: 20px;

  .menu-list {
    display: flex;
    align-items: center;
    gap: 18px;

    li {
      a {
        color: #252A30;
        padding: 4px 0;
        font-size: 14px;
        transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
        transition-duration: 400ms;
        transition-property: color;
        position: relative;

        &:after {
          content: "";
          pointer-events: none;
          bottom: -2px;
          left: 50%;
          position: absolute;
          width: 0;
          height: 2px;
          background-color: #7949F5;
          transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
          transition-duration: 400ms;
          transition-property: width, left;
        }

        &:hover {
          transition: all .2s ease;
          color: #7949F5;

          &:after {
            width: 100%;
            left: 0;
          }
        }

      }
    }
  }
}

.mob-header {
  height: 60px;
  background-color: #252A30;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.humburger-wrapper {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #F7FF54;

  &.active {
    background-color: #fff;
  }
}

.hamburger {
  cursor: pointer;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  height: 3em;
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: #7949F5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
  transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}

.mob-menu-modal {

  .mob-menu-wrapper {
    border-radius: 16px;
    background-color: #252A30;
  }

  .mob-menu-list {
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
  }

  .menu-contact {
    padding: 24px;

    .text {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.03em;
    }

    a {
      color: #F7FF54;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.03em;
    }
  }

  hr {
    color: #fff;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;

    li {
      a {
        padding: 5px 0;
        color: #252A30;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.03em;
      }
    }
  }
}

@media screen and (max-width: 1199.98px) {
  header {
    height: 60px;
  }
}